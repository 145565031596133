import { amplitude } from '@sennder/senn-node-analytics-frontend'
import { AmplitudeLogLevel } from '@sennder/senn-node-analytics-frontend/lib/types'
import { OrgType, Tenant } from '@sennder/senn-node-microfrontend-interfaces'
import { AppAmplitudeLogger } from '@sennder/shell-utilities'

import { AMPLITUDE_API_KEY, isUatEnv } from '@/common/config'
import { executeSafelyWithLogging } from './errors-handler'
import { getStateData, getStateProfile } from '@/store'
import { logger } from './logger/loggers'
import { DEFAULT_EVENT_SOURCE } from './analyticsProvider'

type UserAnalyticsSettings = {
  onLogin: boolean
}

export function initAnalytics() {
  return executeSafelyWithLogging(() => {
    if (isUatEnv()) {
      logger.warn(
        '[chartering-shell - Analytics]: Amplitude is not available in UAT environment',
        {}
      )
      return
    }
    if (!AMPLITUDE_API_KEY) {
      throw new Error(
        '[chartering-shell - Analytics]: AMPLITUDE_API_KEY not found'
      )
    }
    amplitude.init({
      token: AMPLITUDE_API_KEY,
      appName: 'orcas',
      amplitudeConfig: {
        minIdLength: 3,
        loggerProvider: new AppAmplitudeLogger(logger),
        logLevel: AmplitudeLogLevel.Warn,
      },
      source: DEFAULT_EVENT_SOURCE,
    })
  }, 'amplitude.init')
}

export function identifyUserInAnalytics({ onLogin }: UserAnalyticsSettings) {
  const stateData = getStateData()
  const analyticsFeaturesFlags = Object.fromEntries(
    Object.entries(stateData.featureFlags).filter(([flag]) =>
      flag.startsWith('exp')
    )
  )
  const profile = getStateProfile()

  const analyticsUser = {
    tenant: Tenant.SENNDER,
    userId: String(profile.id),
    userName: profile.name,
    language: profile.language,
    orgType: mapOrgType(stateData.org?.orgType),
    ...analyticsFeaturesFlags,
  }

  const deprecatedUserProps = ['uiLanguage', 'username']

  executeSafelyWithLogging(
    () =>
      amplitude.identifyUser(
        analyticsUser,
        onLogin,
        undefined,
        deprecatedUserProps
      ),
    'amplitude.identifyUser'
  )
}

const mapOrgType = (orgType?: OrgType) => {
  if (!orgType) {
    return undefined
  }
  if (orgType === OrgType.CHARTERING_OFFICE) {
    return 'chartering_office'
  }
  return String(orgType)
}

export const resetAnalyticsSession = async () => {
  executeSafelyWithLogging(() => amplitude.endSession(), 'amplitude.endSession')
  initAnalytics()
}

// Used when the user rejects analytics cookies,
// all user data will be removed from the analytics integration as well as any event submissions will be terminated
export const stopAnalytics = () => {
  executeSafelyWithLogging(
    () => amplitude.stopTracking(),
    'amplitude.stopTracking'
  )
}
