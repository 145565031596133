import { LDContext, identify } from '@sennder/senn-node-feature-flags-frontend'
import { getStateData, getStateProfile } from '@/store'
import { userIpManager } from '@/plugins/userIp'
import { LAUNCH_DARKLY_STATIC_KEY } from '@/common/config'

export const identifyUserInLaunchDarkly = async () => {
  const profile = getStateProfile()

  const ldContext: LDContext = {
    kind: 'user',
    key: profile.id,
    email: profile.email,
    name: profile.name,
    organisationType: 'chartering_office',
    organisationId: getStateData().org?.orgID,
  }

  await identify(ldContext)
  console.info(
    '[chartering-shell - LaunchDarkly]: User is identified in LaunchDarkly'
  )
}

export const getLaunchDarklyAnonymousContext = async (): Promise<LDContext> => {
  const userIpAddress = await userIpManager.get()
  return {
    key: LAUNCH_DARKLY_STATIC_KEY,
    ip: userIpAddress,
  }
}

export const anonymizeUserInLaunchDarkly = async () => {
  await identify(await getLaunchDarklyAnonymousContext())
  console.info(
    '[chartering-shell - LaunchDarkly]: User is anonymized in LaunchDarkly'
  )
}
