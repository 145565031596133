import { RouteMeta, Router } from 'vue-router'

import {
  IModulesConfig,
  IOrcasSharedData,
} from '@sennder/senn-node-microfrontend-interfaces'
import { createMfWrapper, MicroFrontendLogger } from '@sennder/shell-utilities'

import {
  Middleware,
  isAuthenticated,
  projectMiddlewares,
} from './middlewares/middlewares'
import { authRoutes } from '@/modules/auth'
import { moduleConfiguration } from '@/modules-configuration'
import { registerMiddlewares } from './middlewares/middlewares-registrator'
import { AppAnalyticsProvider, analytics } from '@/services/analyticsProvider'
import {
  OrcasExtendedData,
  getStateCallbacks,
  getStateData,
  getStateProviders,
} from '@/store'
import router from '@/router'
import { getFederatedConfig } from '@/services/getFederatedConfig'
import { logger } from '@/services/logger/loggers'
import { isLocalEnv } from '@/common/config'
import { loggerInstance } from '@/services/logger'

const PAGE_VIEW_EXCLUDE_COMPONENTS = ['marketplace-mf-component']

const getFullData = () => {
  const data = getStateData()
  if (!data.user || !data.profile) {
    throw new Error(msg('state.data.(user | profile) is not initialized'))
  }
  if (!data.company) {
    throw new Error(msg('state.data.company is not initialized'))
  }
  return data as IOrcasSharedData<OrcasExtendedData>
}

export const msg = (m: string) => `[µ-frontend]: ${m}`

const createComponent = (module: IModulesConfig) => {
  return () => {
    return createMfWrapper<IOrcasSharedData>({
      allowEnvironmentOverrides: isLocalEnv(),
      getData: async () => ({
        data: getFullData(),
        callbacks: getStateCallbacks(),
        providers: getStateProviders(),
      }),
      router,
      hooks: {
        failure: logger.error,
      },
      providers: {
        getAnalytics: (ctx) => new AppAnalyticsProvider(ctx),
        getLogger: (ctx) => new MicroFrontendLogger(ctx, () => loggerInstance),
      },
      mf: {
        id: module.component,
        fml: getFederatedConfig(module),
        context: {
          analytics: module.analyticsContext,
          logger: module.logContext,
        },
      },
    })
  }
}

const addModuleRoute = (module: IModulesConfig) => {
  const component = createComponent(module)

  module.middlewares = module.middlewares ?? []
  const middlewares: Middleware[] = module.middlewares
    .filter((middlewareName) => projectMiddlewares.has(middlewareName))
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((middlewareName) => projectMiddlewares.get(middlewareName)!)

  router.addRoute({
    path: module.route,
    name: module.name,
    component,
    meta: {
      layout: module.layout,
      middlewares,
      npmName: module.component,
      analyticsContext: module.analyticsContext,
    },
    children: [
      {
        path: ':catchAll(.*)',
        name: module.name,
        meta: {
          middlewares,
          npmName: module.component,
          analyticsContext: module.analyticsContext,
        },
        component,
      },
    ],
  })
}

export const registerRoutesAndMiddlewares = (router: Router) => {
  for (const route of authRoutes) {
    router.addRoute(route)
  }
  router.addRoute({
    path: '/',
    redirect: '/login',
  })
  router.addRoute({
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../NotFound.vue'),
    meta: {
      middlewares: [isAuthenticated],
    },
  })

  moduleConfiguration.forEach((module: IModulesConfig) =>
    addModuleRoute(module)
  )

  registerMiddlewares(router)
  router.beforeResolve((to) => {
    const { name, meta } = to
    setPageTitle(String(name), meta)

    // TODO: remove automatic trackPage() calls when all mFs track their own pages
    const component = meta.npmName
    if (
      (!component || !PAGE_VIEW_EXCLUDE_COMPONENTS.includes(component)) &&
      meta.analyticsContext
    ) {
      analytics.trackPage(meta.analyticsContext.module, {
        module: meta.analyticsContext.module,
      })
    }
  })
}

const setPageTitle = (name: string, meta: RouteMeta): void => {
  const pageName = meta?.title || name.charAt(0).toUpperCase() + name.slice(1)
  document.title = `${pageName} | Chartering - Sennder`
}
