import { getStateProfile, loadState } from '@/store'
import { identifyUserInLaunchDarkly } from '@/services/launchDarkly'
import { getAuthToken } from '@/services/authManager'
import startAsyncService from '@/services/retry-service'
import { initMenuItems } from '@/layouts/menuHelper'
import { identifyUserInTracking } from '@/services/tracking'
import { translationProvider } from '@/services/translationProvider'

const LAUNCH_DARKLY_SERVICE_NAME = 'Launch Darkly'

export const loadUserData = async (onLogin = false) => {
  const authToken = await getAuthToken()
  if (!authToken) {
    console.warn('[userDataHelper] - User not logged in')
    return
  }

  if (!(await loadState())) {
    return
  }
  await initMenuItems()

  // TODO: Implement logic to identify user or stop tracking analytics accordingly to user consent

  startAsyncService(LAUNCH_DARKLY_SERVICE_NAME, identifyUserInLaunchDarkly)

  identifyUserInTracking(onLogin)
  await translationProvider.set(getStateProfile().language)
}
